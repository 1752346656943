<template>
	<div style="height: 100%;">
		<main-table v-if="show_table">
			<el-form slot="header" :inline="true" :model="params" :size="theme.size">
				<el-form-item >
					<el-input style="width: 140px;" v-model="params.keyword" placeholder="备注说明" clearable></el-input>
				</el-form-item>
				<el-form-item style="width: 180px;" v-if="!admin_user.shop_id">
					<el-select v-model="params.shop_id" placeholder="城市站点" filterable clearable>
						<el-option v-for="shop in shops" :key="shop.id" :label="shop.name" :value="shop.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select style="width: 180px;" v-model="params.employees" placeholder="请选择员工" filterable  multiple collapse-tags>
						<el-option v-for="employee in employees" :key="employee.id" :label="employee.name" :value="employee.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item >
					<el-select style="width: 220px;" v-model="params.insurance_temp_id" placeholder="投保产品" filterable  multiple collapse-tags>
						<el-option v-for="temp in temps" :key="temp.id" :label="temp.name" :value="temp.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select style="width: 120px;" slot="label" v-model="params.date_type" placeholder="请选择时间类型" filterable clearable>
						<el-option label="失效时间" value="expired"></el-option>
						<el-option label="创建时间" value="created"></el-option>
						<el-option label="更新时间" value="updated"></el-option>
					</el-select>
					<el-date-picker v-model="params.date" :picker-options="$config.DATE_PICKER" type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="getInsurances({ ...params, page: 1})">查询</el-button>
					<el-button type="primary" @click="$refs['products'].open()">投保产品</el-button>
				</el-form-item>
			</el-form>
			<el-table slot="table" ref="insurance_table" class="bill scroll-wrapper absolute" max-height="100%" :data="insurances" :size="theme.size" @sort-change="sortChange" show-summary>
				<el-table-column prop="employee.name" label="受益人" min-width="90"></el-table-column>
				<el-table-column prop="name" label="名称" min-width="100">
					<el-link slot-scope="scope" type="primary" :disabled="!$utils.update($api.URI_INSURANCES)" @click="insurance = { ...scope.row }; insurance_dialog = true;">{{scope.row.name}}</el-link>
				</el-table-column>
				<el-table-column label="缴费" min-width="50" :formatter="({once}) => { return once ? '当月' : '每月'; }"></el-table-column>
				<el-table-column label="基数" prop="base" min-width="100" sortable="custom" :formatter="({base}) => { return (base*1) + ' 元'}"></el-table-column>
				<el-table-column label="公司缴纳" align="center">
					<el-table-column label="比例" min-width="80" :formatter="({ent}) => { return (ent*1) + ' %'}"></el-table-column>
					<el-table-column label="金额" prop="ent_amount" min-width="90" :formatter="({ent_amount}) => { return (ent_amount*1) + ' 元'}"></el-table-column>
				</el-table-column>
				<el-table-column label="个人缴纳" align="center">
					<el-table-column label="比例" min-width="80" :formatter="({ind}) => { return (ind*1) + ' %'}"></el-table-column>
					<el-table-column label="金额" prop="ind_amount" min-width="90" :formatter="({ind_amount}) => { return (ind_amount*1) + ' 元'}"></el-table-column>
				</el-table-column>
				<el-table-column label="小计" prop="sub_amount" min-width="80" :formatter="({sub_amount}) => { return (sub_amount*1) + ' 元'}"></el-table-column>
				<el-table-column label="备注说明" prop="remarks" min-width="100" show-overflow-tooltip></el-table-column>
				<el-table-column label="操作时间" align="center" min-width="200">
					<template v-if="theme.width >= 1420">
						<el-table-column label="失效" prop="expired_at" min-width="150" :formatter="({expired_at}) => { return expired_at || '长期'; }"></el-table-column>
						<el-table-column label="更新" prop="updated_at" min-width="150"></el-table-column>
						<el-table-column label="创建" prop="created_at" min-width="150"></el-table-column>
					</template>
					<template slot-scope="scope">
						<p v-if="scope.row.expired_at">失效于 {{scope.row.expired_at}}</p>
						<p v-if="scope.row.updated_at">更新于 {{scope.row.updated_at}}</p>
						<p v-if="scope.row.created_at">创建于 {{scope.row.created_at}}</p>
					</template>
				</el-table-column>
				<el-table-column min-width="60">
					<el-link slot="header" type="primary" size="mini" :disabled="!$utils.create($api.URI_INSURANCES)" @click="insurance_dialog = true">添加</el-link>
					<template slot-scope="scope">
						<el-button type="text" :disabled="!$utils.delete($api.URI_INSURANCES)" @click="delInsurance(scope.row)" v-if="!scope.row.deleted_at">删除</el-button>
						<el-button type="text" :disabled="!$utils.restore($api.URI_INSURANCES)" @click="resInsurance(scope.row)" v-else>恢复</el-button>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination
				slot="footer"
				:layout="$config.PAGE_LAYOUT"
				:page-sizes="$config.PAHE_SIZES"
				:page-size="params.perPage"
				:current-page="params.page"
				:total="params.total"
				@size-change="(v) => { getInsurances({ ...params, page: 1, perPage: v }) }"
				@current-change="(v) => { getInsurances({ ...params, page: v }) }"
				background>	
			</el-pagination>
		</main-table>
		<main-table v-else tool>
			<el-result slot="table" icon="warning" title="警告提示" subTitle="暂不支持为员工投保，请前往薪酬列表初始化薪酬配置字段"></el-result>
		</main-table>
		<el-dialog width="460px" :title="insurance.id ? '编辑投保' : '创建投保'" :visible.sync="insurance_dialog" @closed="insurance = {}" append-to-body destroy-on-close>
			<el-form label-width="90px" ref="insurance_form" :model="insurance" :rules="insurance_rules" :size="theme.size" status-icon>
				<el-form-item label="员工/类型" style="margin-bottom: 0;" v-if="!insurance.id" required>
					<el-col :span="10">
						<el-form-item prop="employee_id">
							<el-select v-model="insurance.employee_id" placeholder="请选择员工" filterable>
								<el-option v-for="employee in employees" :key="employee.id" :label="employee.name" :value="employee.id"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col style="text-align: center;" :span="2">-</el-col>
					<el-col :span="12">
						<el-form-item prop="insurance_temp_id">
							<el-select v-model="insurance.insurance_temp_id" placeholder="投保产品" filterable clearable>
								<el-option v-for="temp in temps" :key="temp.id" :label="temp.name" :value="temp.id"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
				</el-form-item>
				<el-form-item label="基数/缴费" style="margin-bottom: 0;" required>
					<el-col :span="10">
						<el-form-item prop="base">
							<el-input-number style="width: 100%;" placeholder="基数" v-model="insurance.base" :precision="2" :step="0.01" :min="0.01"></el-input-number>
						</el-form-item>
					</el-col>
					<el-col style="text-align: center;" :span="2">-</el-col>
					<el-col :span="10">
						<el-form-item prop="once">
							<el-switch v-model="insurance.once" active-value="1" inactive-value="0" active-text="当月" inactive-text="每月"></el-switch>
						</el-form-item>
					</el-col>
					<el-col :span="2">
						<el-tooltip placement="top">
							<div slot="content">默认每月缴费<br>选择当月缴费即根据创建时间，且必须选择失效时间</div>
							<i class="el-icon-question"></i>
						</el-tooltip>
					</el-col>
				</el-form-item>
				<el-form-item label="失效时间" prop="expired_at" v-if="insurance.once == 1">
					<el-date-picker v-model="insurance.expired_at" type="datetime" placeholder="选择失效时间" :picker-options="{ disabledDate (time) { return time.getTime() < Date.now(); } }"></el-date-picker>
				</el-form-item>
				<el-form-item label="备注说明" prop="remarks">
					<el-input v-model="insurance.remarks" type="textarea" placeholder="请输入备注说明。非必填" rows="3" maxlength="255" show-word-limit clearable></el-input>
				</el-form-item>
				<el-form-item label="证明附件" prop="attachments">
					<el-upload
						list-type="picture"
						:action="$api.URI_UPLOADS"
						:data="{type: insurances_cfg.type}"
						:accept="insurances_cfg.ext.map(e => { return '.'+e; }).join(',')"
						:on-success="(r, f, l) => { onSuccess(l, 'attachments'); }"
						:on-preview="(f) => { openFile(f.url); }"
						:before-upload="beforeFeeAttach"
						:file-list="insurance.attachments">
						<el-button type="text" size="mini" icon="el-icon-upload">点击上传</el-button>
						<p slot="tip" style="font-size: 12px; color: #999; line-height: 1.5;">只能上传 {{insurances_cfg.ext.join('/').toUpperCase()}} 文件，且大小不超过 {{insurances_cfg.format_size_unit}}。<br>非必要，一般建议上传商业保单购买回执之类相关证明文件</p>
					</el-upload>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="submitForm('insurance_form')">{{insurance.id ? '提交保存'  : '提交创建'}}</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
		<products ref="products"></products>
	</div>
</template>

<script>
	import { mapState } from 'vuex';
	import mainTable from '@/components/main-table';
	import products from './products';

	export default {
		components: {
			mainTable,
			products
		},
		computed: {
			...mapState(['theme', 'admin_user']),
			insurances_cfg () {
				return this.$utils.uploadConfig('insurances');
			}
		},
		methods: {
			sortChange (e) {
				let o = e.order ? (e.prop+':'+e.order) : null;
				let p = this.params;
					p.order = o;
				this.getInsurances(this.params);
			},
			openFile (u) {
				window.open(u);
			},
			onSuccess (list) {
				this.insurance = {
					...this.insurance,
					attachments: list.map((file) => {
						const { response } = file;
						if (response) {
							const { status, id } = response.result;
							if (status == "success") {
								file.id = id;
							}
						}
						return file;
					})
				};
			},
			beforeFeeAttach (file) {
				const { size, format_size_unit } = this.insurances_cfg;
				const is = file.size < size;
				if (!is) return this.$message.error('上传相关证明文件不能超过 '+format_size_unit);
				return is;
			},
			delInsurance ({ id, employee, name }) {
				this.$confirm(employee.name + ' 的 ' + name, '确定要删除吗？', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					this.handleInsurances({action: 'delete', id});
				});
			},
			resInsurance ({ id, employee, name }) {
				this.$confirm(employee.name + ' 的 ' + name, '确定要恢复吗？', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					this.handleInsurances({action: 'restore', id});
				});
			},
			async getInsurances (params, loading = false) {
				const res = await this.$http.get(this.$api.URI_INSURANCES, {params, headers: { loading }});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.insurances = result.data;
				this.temps = result.temps;
				this.employees = result.employees;
				this.show_table = result.show_table;
				this.params = {
					...params,
					total: result.total,
					perPage: parseInt(result.per_page)
				}
			},
			async handleInsurances (params) {
				const res = await this.$http.get(this.$api.URI_INSURANCES, {params, headers: {loading: true}});
				const { code, msg } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.getInsurances(this.params);
				this.$message.success(msg);
			},
			async submitInsurance (data) {
				const res = await this.$http.post(this.$api.URI_INSURANCES, data);
				const { code, msg: message } = res.data;
				if (code != 0) return this.$message.error(message);
				this.$message.success({
					message,
					onClose: () => {
						this.getInsurances(this.params);
						this.insurance_dialog = false;
					}
				});
			},
			submitForm (ref) {
				this.$refs[ref].validate(async (valid) => {
					if (!valid) return this.$message.error("提交表单有误！");
					switch (ref) {
						case 'insurance_form':
							this.submitInsurance(this.insurance);
						break;
					}
				});
			}
		},
		data() {
			return {
				insurance: {},
				insurances: [],
				employees: [],
				temps: [],
				show_table: true,
				insurance_dialog: false,
				insurance_rules: {
					employee_id: [{ required: true, message: '必须选择员工', trigger: 'blur'}],
					insurance_temp_id: [{ required: true, message: '必须选择投保产品', trigger: 'blur'}],
					base: [{ required: true, message: '必须填写金额', trigger: 'blur'}],
					expired_at: [{ required: true, message: '必须选择失效时间', trigger: 'blur'}],
				},
				params: {
					perPage: 10
				}
			}
		},
		created () {
			this.getInsurances(this.params, true);
		}
	};
</script>